import React from 'react';
import { getLayoutItem } from '../helpers/layoutHelper';
import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function LayoutFullContainer({ node }) {

    const { field_full_container_bg } = node.attributes;
    const layout_items = Object.values(node.relationships).map(getLayoutItem);
    //const { } = node.attributes;
    return (
        <>
            <section className="full__container">
                <div className="full__container--box">{layout_items}</div>
                <div className="full__bg--fafaicon">
                    <div className="full__bg--fafaicon--container">
                        <FontAwesomeIcon icon={field_full_container_bg?.icon_name} />
                    </div>
                </div>
            </section>
        </>
    );
}
