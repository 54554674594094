import React from 'react';

export function LayoutAboutMinibox({ node }) {

    //const { } = node.attributes;
    return (
        <>
            {/* <pre>{JSON.stringify(node, {}, 4)}</pre> */}
            <article className="project__boxes--box">
                <a href="/" className="project__box--a">
                    <div className="project__boxes--box--img">
                    </div>
                    <div className="project__boxes--box--title">
                        <div className="project__boxes--box--title--h3">
                        </div>
                        <div className="project__boxes--box--title--p">
                        </div>
                    </div>
                </a>
            </article>
        </>
    );
}
