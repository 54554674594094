import React from 'react';
import { getLayoutItem } from '../helpers/layoutHelper';

export function LayoutSidebarContainer({ node }) {

    const layout_items = Object.values(node.relationships).map(getLayoutItem);
    return (
        <>
            <div className="layout__sidebar--container">{layout_items}</div>
        </>
    );
}
