import React from 'react';
import { getLayoutItem } from '../helpers/layoutHelper';
import { Link } from 'gatsby';

import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function LayoutOurTeam({ node }) {

    const layout_items = Object.values(node.relationships).map(getLayoutItem);
    const { title, field_our_team_title, field_our_team_bg, field_our_team_link } = node.attributes;
    return (
        <>
            {/* <pre>{JSON.stringify(node, {}, 4)}</pre> */}
            <article className="our-team__team--boxes--box">
                <Link className="our-team__box--a" to={field_our_team_link?.uri.slice(9)}>
                    <div className="our-team__team--boxes--box--img team__box--img">
                        {layout_items}
                        <FontAwesomeIcon icon={ field_our_team_bg?.icon_name } />
                    </div>
                </Link>
                <div className="our-team__team--boxes--box--text">
                    <h3>{ title }</h3>
                    <p>{ field_our_team_title }</p>
                </div>
            </article>
        </>
    );
}
