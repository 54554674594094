import React from 'react';
import HtmlParser from '../helpers/htmlParser';

export function LayoutItemSeoTextBox({ node }) {

    const { field_seo_text_box, field_box_id, field_seo_active } = node.attributes;
    return (
        <>
            <div className={`${field_seo_active} seo-text-box--container`} id={field_box_id}>
                <HtmlParser html={ field_seo_text_box?.processed }/>
            </div>
        </>
    );
}
