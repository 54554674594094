import React,{useState} from 'react';
import { getLayoutItem } from '../helpers/layoutHelper';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/navigation"
import "swiper/css/thumbs"

import SwiperCore, {
    Navigation,Thumbs
  } from 'swiper';

  SwiperCore.use([Navigation,Thumbs]);

export function LayoutPortfolioSlideshow({ node }) {
    const layout_items = Object.values(node.relationships).map(getLayoutItem);
    
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    function showGallery() {

        if (document.getElementById) {
            var fcOpen = document.getElementById("portfolio-gallery");
            fcOpen.classList.remove("hide-gallery");
        }
        return false;
    
    }

    function hideGallery() {

        if (document.getElementById) {
            var fcClose = document.getElementById("portfolio-gallery");
            fcClose.classList.add("hide-gallery");
        }
        return false;
    
    }

    return (
        <div className="swiper__container">
            <div className="swiper__container--main">
                <Swiper
                modules={[Navigation]}
                spaceBetween={10}
                navigation={true}
                breakpoints={{
                    1920: {
                        slidesPerView: 3
                    },
                    425: {
                        width: 425,
                        slidesPerView: 1,
                    }
                  }}
                >
                    {layout_items.map((desc) => (
                        <SwiperSlide onClick={() => showGallery()}>
                            <div className="open-gallery">{desc}</div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div id="portfolio-gallery" className="swiper__container--gallery hide-gallery">
                <div className="swiper__container--gallery-box">
                    <div className="swiper__container--gallery-box-close" onClick={() => hideGallery()}>X</div>
                    <Swiper style={{'--swiper-navigation-color': '#fff','--swiper-pagination-color': '#fff'}} spaceBetween={10} navigation={true} thumbs={{ swiper: thumbsSwiper }} className="mySwiper2">
                        {layout_items.map((desc) => (
                                <SwiperSlide>
                                    {desc}
                                </SwiperSlide>
                            ))}
                    </Swiper>
                    <Swiper onSwiper={setThumbsSwiper} spaceBetween={10} slidesPerView={3} freeMode={true} watchSlidesProgress={true} className="mySwiper">
                        {layout_items.map((desc) => (
                                <SwiperSlide>
                                    {desc}
                                </SwiperSlide>
                            ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
}
