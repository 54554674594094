import React from 'react';
import { Link } from 'gatsby';

export function LayoutLink({ node }) {

    const { field_layout_link } = node.attributes;
    return (
        <>
            <div className="project__btn">
                <Link to={field_layout_link?.uri.slice(9)}>{field_layout_link?.title}</Link>
            </div>
        </>
    );
}