import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import React, { useState } from 'react';
import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import amMap from '../images/am-world-map.png';
const { mapTextData } = require('../helpers/mapdata.js');

const MyMapComponent = withScriptjs(
    withGoogleMap((props) => (
        <GoogleMap
            defaultZoom={13}
            center={props.center}
            defaultCenter={{ lat: 37.4289985, lng: -122.2531358 }}>
            {props.isMarkerShown && <Marker position={props.center} />}
        </GoogleMap>
    ))
);

function MapMarkerInfo(div) {
    var mapInfo = [];

    if (div === 'sanfrancisco') {
        mapInfo = mapTextData['sanfrancisco'];
    }
    if (div === 'washington') {
        mapInfo = mapTextData['washington'];
    }
    if (div === 'newyork') {
        mapInfo = mapTextData['newyork'];
    }
    if (div === 'london') {
        mapInfo = mapTextData['london'];
    }
    if (div === 'budapest') {
        mapInfo = mapTextData['budapest'];
    }
    if (div === 'dubai') {
        mapInfo = mapTextData['dubai'];
    }

    const { location, maptitle, phone, fafaicon, fafaicontext, fafaiconnumber } = mapInfo;

    return (
        <div className='contact__map-marker--info' id={location}>
            <h3>{maptitle}</h3>
            <div className='contact__map-marker--info--box'>
                <div className='contact__map-top--info--box--flex contact__map-marker--info--box--phone'>
                    <div className='contact__map-marker--info--box--phone--fafaicon'>
                        <FontAwesomeIcon icon={'phone'} />
                    </div>
                    <p>
                        <span>Phone:</span>
                        {phone}
                    </p>
                </div>
                <div className='contact__map-top--info--box--flex contact__map-marker--info--box--toll-free'>
                    <div className='contact__map-marker--info--box--toll-free--fafaicon'>
                        <FontAwesomeIcon icon={fafaicon} />
                    </div>
                    <p>
                        <span>{fafaicontext}</span>
                        {fafaiconnumber}
                    </p>
                </div>
                <div className='contact__map-top--info--box--flex contact__map-marker--info--box--skype'>
                    <div className='contact__map-marker--info--box--skype--fafaicon'>
                        <FontAwesomeIcon icon={['fab', 'skype']} />
                    </div>
                    <p>
                        <span>Skype:</span>ActiveMediaUSA
                    </p>
                </div>
            </div>
        </div>
    );
}

function MapDivs(position, div) {
    var mapText = [];

    if (position.lat === 37.4289985) {
        mapText = mapTextData['sanfrancisco'];
    }
    if (position.lat === 38.8006) {
        mapText = mapTextData['washington'];
    }
    if (position.lat === 40.7420003) {
        mapText = mapTextData['newyork'];
    }
    if (position.lat === 51.3772567) {
        mapText = mapTextData['london'];
    }
    if (position.lat === 47.478345) {
        mapText = mapTextData['budapest'];
    }
    if (position.lat === 24.1708333) {
        mapText = mapTextData['dubai'];
    }

    if (div === 'top') {
        const { location, maptitle, phone, fafaicon, fafaicontext, fafaiconnumber } = mapText;

        return (
            <div className='contact__map-top--info' id={location}>
                <h3>{maptitle}</h3>
                <div className='contact__map-top--info--box'>
                    <div className='contact__map-top--info--box--flex contact__map-top--info--box--phone'>
                        <div className='contact__map-top--info--box--phone--fafaicon'>
                            <FontAwesomeIcon icon={'phone'} />
                        </div>
                        <p>
                            <span>Phone:</span>
                            {phone}
                        </p>
                    </div>
                    <div className='contact__map-top--info--box--flex contact__map-top--info--box--toll-free'>
                        <div className='contact__map-top--info--box--toll-free--fafaicon'>
                            <FontAwesomeIcon icon={fafaicon} />
                        </div>
                        <p>
                            <span>{fafaicontext}</span>
                            {fafaiconnumber}
                        </p>
                    </div>
                    <div className='contact__map-top--info--box--flex contact__map-top--info--box--skype'>
                        <div className='contact__map-top--info--box--skype--fafaicon'>
                            <FontAwesomeIcon icon={['fab', 'skype']} />
                        </div>
                        <p>
                            <span>Skype:</span>ActiveMediaUSA
                        </p>
                    </div>
                </div>
            </div>
        );
    }
    if (div === 'bottom') {
        const { text1, text2, text3, link } = mapText;

        return (
            <div className='contact__map-bottom--info'>
                <div className='contact__map-bottom--info--location'>
                    <div className='contact__map-bottom--info--location--first'>
                        <span></span>
                        <p>{text1}</p>
                    </div>
                    <p>{text2}</p>
                    <p>{text3}</p>
                </div>
                <Link to={link}>Direction</Link>
            </div>
        );
    }
}

export function LayoutItemMap() {
    const [position, setPosition] = useState({ lat: 37.4289985, lng: -122.2531358 });
    return (
        <div className='contact__google-map' id='map-container'>
            <div className='contact__google-map--world-map'>
                <div className='contact__google-map--markers--container'>
                    <div className='content__top'>
                        <div className='hide-this-element'>
                            <h2>Our Locations</h2>
                        </div>
                        <div className='content__top--fafaicon'>
                            <div className='content__top--fafaicon--container'>
                                <FontAwesomeIcon icon={'globe-americas'} />
                            </div>
                        </div>
                        <h2> Our Locations</h2>
                    </div>
                    <div className='map'>
                        <img src={amMap} alt='ActiveMedia hero'></img>
                    </div>
                    <div className='contact__google-map--markers sanfrancisco'>
                        <button
                            type='button'
                            aria-label='SanFrancisco'
                            onClick={() =>
                                setPosition({ lat: 37.4289985, lng: -122.2531358 })
                            }></button>
                        <div className='contact__google-map--markers--info'>
                            {MapMarkerInfo('sanfrancisco')}
                        </div>
                    </div>
                    <div className='contact__google-map--markers washington'>
                        <button
                            type='button'
                            aria-label='Washington'
                            onClick={() => setPosition({ lat: 38.8006, lng: -77.04009 })}></button>
                        <div className='contact__google-map--markers--info'>
                            {MapMarkerInfo('washington')}
                        </div>
                    </div>
                    <div className='contact__google-map--markers newyork'>
                        <button
                            type='button'
                            aria-label='NewYork'
                            onClick={() =>
                                setPosition({ lat: 40.7420003, lng: -73.9931407 })
                            }></button>
                        <div className='contact__google-map--markers--info'>
                            {MapMarkerInfo('newyork')}
                        </div>
                    </div>
                    <div className='contact__google-map--markers london'>
                        <button
                            type='button'
                            aria-label='London'
                            onClick={() =>
                                setPosition({ lat: 51.3772567, lng: -0.0675111 })
                            }></button>
                        <div className='contact__google-map--markers--info'>
                            {MapMarkerInfo('london')}
                        </div>
                    </div>
                    <div className='contact__google-map--markers budapest'>
                        <button
                            type='button'
                            aria-label='Budapest'
                            onClick={() =>
                                setPosition({ lat: 47.478345, lng: 19.027352 })
                            }></button>
                        <div className='contact__google-map--markers--info'>
                            {MapMarkerInfo('budapest')}
                        </div>
                    </div>
                    <div className='contact__google-map--markers dubai'>
                        <button
                            type='button'
                            aria-label='Dubai'
                            onClick={() => setPosition({ lat: 24.1708333, lng: 52.595 })}></button>
                        <div className='contact__google-map--markers--info'>
                            {MapMarkerInfo('dubai')}
                        </div>
                    </div>
                </div>
            </div>
            <div className='contact__google-map--container'>
                <div className='contact__google-map--container--top-box'>
                    <div className='service__top--fafaicon'>
                        <div className='service__top--fafaicon--container'>
                            <FontAwesomeIcon icon={'building'} />
                        </div>
                    </div>
                    {MapDivs(position, 'top')}
                </div>
                <div className='contact__google-map--container--middle-box'>
                    <MyMapComponent
                        isMarkerShown
                        googleMapURL='https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBuOtaasGGIpALrBFbqeUn3r5L_B5LQ_vI'
                        loadingElement={<div style={{ height: `500px` }} />}
                        containerElement={<div style={{ height: `375px`, padding: `21px` }} />}
                        center={position}
                        mapElement={<div style={{ height: `303px`, width: `464px` }} />}
                    />
                </div>
                <div className='contact__google-map--container--bottom-box'>
                    {MapDivs(position, 'bottom')}
                </div>
            </div>
        </div>
    );
}
export default LayoutItemMap;
