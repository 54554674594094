import React from 'react';
import { getLayoutItem } from '../helpers/layoutHelper';

export function LayoutResources({ node }) {

    const layout_items = Object.values(node.relationships).map(getLayoutItem);
    //const { title } = node.attributes;
    return (
        <>
            <div className="hero__image">
                {layout_items}
            </div>
        </>
    );
}