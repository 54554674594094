import React from 'react';
import { Link } from 'gatsby';
import HtmlParser from '../helpers/htmlParser';
import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function LayoutSingleService({ node }) {

    const { field_layout_s_service_fafaicon, field_layout_s_service_title, field_layout_s_service_text, field_layout_s_service_link } = node.attributes;
    return (
        <>
        {/* <pre>{JSON.stringify(node, {}, 4)}</pre> */}
            <div className="service__table--boxes--box service__table--box-green service__box--01">
                <Link className="service__table--a" to={`/${field_layout_s_service_link?.uri?.slice(10)}`} rel="prev">
                    <div className="service__table--minibox">
                        <div className="table__box--title">
                            <div className="box__title--icon"><FontAwesomeIcon icon={field_layout_s_service_fafaicon?.icon_name} /></div>
                            <h3>{field_layout_s_service_title}</h3>
                        </div>
                        <div className="table__box--text">
                            <p><HtmlParser html={ field_layout_s_service_text ? field_layout_s_service_text.processed : "" }/></p>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    );
}
