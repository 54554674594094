import React from 'react';
import HtmlParser from '../helpers/htmlParser';
import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function LayoutMapButton({ node }) {

    const { title, field_map_button_fafaicon, field_map_button_text } = node.attributes;
    return (
        <>
            <div className="map-button">
                <div className="hide-this-element">
                    <h2>{title}</h2>
                </div>
                <div className="map-button--fafaicon">
                    <div className="map-button--fafaicon--container">
                        <FontAwesomeIcon icon={field_map_button_fafaicon?.icon_name} />
                    </div>
                </div>
                <h2>
                    <HtmlParser html={ field_map_button_text?.processed }/>
                </h2>
            </div>
        </>
    );
}
