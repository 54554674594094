import React from 'react';
import HtmlParser from '../helpers/htmlParser';
import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function showHideWebQuote(id, btn) {

    if (document.getElementById) {
        var webdivid = document.getElementById(id);
        var webdivs = document.getElementsByClassName("ask-for-quote__form-container--web-design--sheet");
        var webdivbtnid = document.getElementById(btn);
        var webbtndivs = document.getElementsByClassName("ask_for_quote__trigger");

        Array.from(webdivs).forEach(function (web) {
            if (web.classList.contains("active-sheet")) {
                web.classList.remove("active-sheet");
            }
        })
        Array.from(webbtndivs).forEach(function (wbbtn) {
            if (wbbtn.classList.contains("active-trigger")) {
                wbbtn.classList.remove("active-trigger");
            }
        })
        webdivid.classList.add("active-sheet");
        webdivbtnid.classList.add("active-trigger");
    }
    return false;

}

function showHideMobileQuote(id, btn) {

    if (document.getElementById) {
        var mobiledivid = document.getElementById(id);
        var mobiledivs = document.getElementsByClassName("ask-for-quote__form-container--mobile-services--sheet");
        var webdivbtnid = document.getElementById(btn);
        var webbtndivs = document.getElementsByClassName("ask_for_quote__trigger");

        Array.from(mobiledivs).forEach(function (mobile) {
            if (mobile.classList.contains("active-sheet")) {
                mobile.classList.remove("active-sheet");
            }
        })
        Array.from(webbtndivs).forEach(function (wbbtn) {
            if (wbbtn.classList.contains("active-trigger")) {
                wbbtn.classList.remove("active-trigger");
            }
        })
        mobiledivid.classList.add("active-sheet");
        webdivbtnid.classList.add("active-trigger");
    }
    return false;

}


export function LayoutAskForQuoteButton({ pageContext, node }) {

    const { title, field_ask_for_quote_fafaicon, field_ask_for_quote_text, field_onclick_event, field_ask_for_quote_btn_id, field_ask_quote_btn_active } = node.attributes;

    const selectDiv = (field_onclick_event) => {
        if (field_onclick_event) {
            if (field_onclick_event.includes("web-design")) {
                return (
                    <div aria-hidden="true" id={field_ask_for_quote_btn_id} onKeyDown={() => showHideWebQuote(field_onclick_event, field_ask_for_quote_btn_id)} className={`ask_for_quote__trigger ${field_ask_quote_btn_active}`} onClick={() => showHideWebQuote(field_onclick_event, field_ask_for_quote_btn_id)}>
                        <div className="hide-this-element">
                        <h2>{title}</h2>
                        </div>
                        <div className="ask_for_quote__top--fafaicon">
                            <div className="ask_for_quote__top--fafaicon--container">
                                <FontAwesomeIcon icon={field_ask_for_quote_fafaicon?.icon_name} />
                            </div>
                        </div>
                        <div>
                            <HtmlParser html={ field_ask_for_quote_text }/>
                        </div>
                    </div>
                )
            }
            if (field_onclick_event.includes("mobile-services")) {
                return (
                    <div aria-hidden="true" id={field_ask_for_quote_btn_id} onKeyDown={() => showHideMobileQuote(field_onclick_event, field_ask_for_quote_btn_id)} className={`ask_for_quote__trigger ${field_ask_quote_btn_active}`} onClick={() => showHideMobileQuote(field_onclick_event, field_ask_for_quote_btn_id)}>
                        <div className="hide-this-element">
                            <h2>{title}</h2>
                            </div>
                            <div className="ask_for_quote__top--fafaicon">
                                <div className="ask_for_quote__top--fafaicon--container">
                                    <FontAwesomeIcon icon={field_ask_for_quote_fafaicon?.icon_name} />
                                </div>
                            </div>
                            <div>
                                <HtmlParser html={ field_ask_for_quote_text }/>
                            </div>
                    </div>
                )
            }
        }
    }
    
    return (
        <>
            <div className="ask_for_quote__top">
                {/*<pre>{JSON.stringify(node, {}, 4)}</pre>*/}
                {selectDiv(field_onclick_event)}    
            </div>
        </>
    );
}
