import React from 'react';
import { Link } from 'gatsby';
import { getLayoutItem } from '../helpers/layoutHelper';
import HtmlParser from '../helpers/htmlParser';

function scrollDown() {
    window.scrollBy(0,1000);   
}

export function LayoutBanner({ node, location }) {

    const layout_items = Object.values(node.relationships).map(getLayoutItem);
    const { field_banner_btn, field_banner_s_text, field_banner_l_text, field_width_class } = node.attributes;

    const layoutClass = field_width_class;

    if (layoutClass !== null) {
        var containerClass = layoutClass;
    } else {
        containerClass = "";
    }

    return (
        <>
            <div className={`hero__image ${containerClass}`}>
                {layout_items}
                <div className="hero__h1text">
                    <div className="hero__h1text--center">
                        <h1><HtmlParser html={ field_banner_l_text?.value }/></h1>
                        <p><span><HtmlParser html={ field_banner_s_text?.value }/></span></p>
                    </div>
                </div>
                <div className="hero__btn btnload">
                    <div onClick={() => scrollDown()}><Link className="load">{field_banner_btn?.title}</Link></div>
                </div>
            </div>
        </>
    );
}
