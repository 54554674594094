import React from 'react';
import HtmlParser from '../helpers/htmlParser';
import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function LayoutSlideshowItem({ node }) {
    const { field_slideshow_item_fafaicon, field_slideshow_item_text, field_active_slideshow_item, field_slideshow_item_id } = node.attributes;
    return (
        <>
            <div id={field_slideshow_item_id} className={`slideshow__container--box ${field_active_slideshow_item}`}>
                <div className="slideshow__container--icon">
                    <FontAwesomeIcon icon={field_slideshow_item_fafaicon?.icon_name} />
                </div>
                <div className="slideshow__container--slider">
                    <HtmlParser html={ field_slideshow_item_text?.processed }/>
            </div>
            </div>
        </>
    );
}
