import React from 'react';
import { getLayoutItem } from '../helpers/layoutHelper';

export function Layout2ColumnBox({ node }) {

    const layout_items = Object.values(node.relationships).map(getLayoutItem);
    //const { } = node.attributes;
    return (
        <>
            <div className="second__article two-column-container">
                <article className="second__article--container">
                    {layout_items}
                </article>
            </div>
        </>
    );
}
