import React from 'react';

export function LayoutResourcesFilter({ node }) {

    const { field_filter_btn, field_href_to } = node.attributes;

    return (
        <a href={field_href_to} className={`filter-btn ${field_filter_btn}`}>{field_filter_btn}</a>
    );
}
