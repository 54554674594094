import React from 'react';

import DrupalFile from '../components/file.js';
import { LayoutImage } from '../layout_items/layout_image.js';
import { LayoutTextBox } from '../layout_items/layout_text_box.js';
import { LayoutBanner } from '../layout_items/layout_banner.js';
import { LayoutLink } from '../layout_items/layout_link.js';
import { LayoutProjects } from '../layout_items/layout_projects.js';
import { LayoutSlideshowItem } from '../layout_items/layout_slideshow_item.js';
import { LayoutSlideshowContainer } from '../layout_items/layout_slideshow_container.js';
import { LayoutFafaiconWithText } from '../layout_items/layout_fafaicon_with_text.js';
import { LayoutResourcesFafaicon } from '../layout_items/layout_resources_fafaicon.js';
import { LayoutAskForQuoteButton } from '../layout_items/layout_ask_for_quote_button.js';
import { LayoutSidebarFafaicon } from '../layout_items/layout_sidebar_fafaicon.js';
import { LayoutFafaicon } from '../layout_items/layout_fafaicon.js';
import { LayoutConsultation } from '../layout_items/layout_consultation_box.js';
import { LayoutServices } from '../layout_items/layout_services.js';
import { LayoutResources } from '../layout_items/layout_resources.js';
import { LayoutLeadership } from '../layout_items/layout_leadership.js';
import { LayoutContainer } from '../layout_items/layout_container.js';
import { LayoutSidebarContainer } from '../layout_items/layout_sidebar_container.js';
import { Layout2ColumnBox } from '../layout_items/layout_2_column_box.js';
import { LayoutServicesContainer } from '../layout_items/layout_service_container.js';
import { LayoutServicesMinibox } from '../layout_items/layout_services_minibox.js';
import { LayoutProjectContainer } from '../layout_items/layout_project_container.js';
import { LayoutProjectMinibox } from '../layout_items/layout_project_minibox.js';
import { LayoutFooterContainer } from '../layout_items/layout_footer_container.js';
import { LayoutPortfolioPage } from '../layout_items/layout_portfolio_page.js';
import { LayoutSingleServiceContainer } from '../layout_items/layout_single_service_container.js';
import { LayoutSingleService } from '../layout_items/layout_single_service.js';
import { LayoutOurTeam } from '../layout_items/layout_our_team.js';
import { LayoutItemMap } from '../layout_items/layout_item_map.js';
import { LayoutOurTeamContainer } from '../layout_items/layout_our_team_container.js'
import { LayoutOurTeamSingle } from '../layout_items/layout_our_team_single.js';
import { LayoutWebform }  from '../layout_items/layout_webform.js';
import { LayoutAskForQuoteSidebar } from '../layout_items/layout_ask_for_quote_sidebar.js';
import { LayoutMapContainer } from '../layout_items/layout_map_container.js';
import { LayoutMapButton } from '../layout_items/layout_map_button.js';
import { LayoutAboutMiniboxContainer } from '../layout_items/layout_about_minibox_container.js';
import { LayoutAboutMinibox } from '../layout_items/layout_about_minibox.js';
import { LayoutServiceBanner } from '../layout_items/layout_service_banner.js';
import { LayoutItemServiceBannerBox } from '../layout_items/layout_item_service_banner_box.js';
import { LayoutItemServiceButton } from '../layout_items/layout_item_service_button.js';
import { LayoutItemSeoFafaicon } from '../layout_items/layout_item_seo_fafaicon.js';
import { LayoutItemSeoTextBox } from '../layout_items/layout_item_seo_text_box.js';
import { LayoutServiceProcessContainer } from '../layout_items/layout_service_process_container.js';
import { LayoutFullContainer } from '../layout_items/layout_full_container.js';
import { LayoutPortfolioBanner } from '../layout_items/layout_portfolio_banner.js';
import { LayoutPortfolioSlideshow } from '../layout_items/layout_item_portfolio_slideshow.js';
import { LayoutHomepageSlideshow } from '../layout_items/layout_item_homepage_slideshow.js';
import { LayoutResourcesFilter } from '../layout_items/layout_item_resources_filter.js';
import { LayoutLetsTalk } from '../layout_items/layout_item_lets_talk.js';
import { LayoutChatSidebar } from '../layout_items/layout_chat_sidebar.js';

import '../styles/core.scss';

const components = {
    node__layout_image: LayoutImage,
    node__layout_webform: LayoutWebform,
    node__layout_our_team_container: LayoutOurTeamContainer,
    node__layout_text_box: LayoutTextBox,
    node__layout_banner: LayoutBanner,
    node__layout_resources: LayoutResources,
    node__layout_our_team: LayoutOurTeam,
    node__layout_our_team_single: LayoutOurTeamSingle,
    node__layout_leadership_qr: LayoutLeadership,
    node__layout_portfolio_page: LayoutPortfolioPage,
    node__layout_link: LayoutLink,
    node__layout_2_column_box: Layout2ColumnBox,
    node__layout_container: LayoutContainer,
    node__layout_sidebar_container: LayoutSidebarContainer,
    node__layout_projects: LayoutProjects,
    node__layout_fafaicon_with_text: LayoutFafaiconWithText,
    node__layout_resources_fafaicon: LayoutResourcesFafaicon,
    node__layout_ask_for_quote_button: LayoutAskForQuoteButton,
    node__layout_sidebar_fafaicon: LayoutSidebarFafaicon,
    node__layout_fafaicon: LayoutFafaicon,
    node__layout_services: LayoutServices,
    node__layout_consultation_box: LayoutConsultation,
    node__layout_services_container: LayoutServicesContainer,
    node__layout_services_minibox: LayoutServicesMinibox,
    node__layout_project_container: LayoutProjectContainer,
    node__layout_project_minibox: LayoutProjectMinibox,
    node__layout_footer_container: LayoutFooterContainer,
    node__layout_single_service_container: LayoutSingleServiceContainer,
    node__layout_ask_for_quote_sidebar: LayoutAskForQuoteSidebar,
    node__layout_single_service: LayoutSingleService,
    node__layout_about_minibox_container: LayoutAboutMiniboxContainer,
    node__layout_about_minibox: LayoutAboutMinibox,
    node__layout_item_map: LayoutItemMap,
    node__layout_map_container: LayoutMapContainer,
    node__layout_map_button: LayoutMapButton,
    node__layout_service_banner: LayoutServiceBanner,
    node__layout_item_service_banner_box: LayoutItemServiceBannerBox,
    node__layout_item_service_button: LayoutItemServiceButton,
    node__layout_item_seo_fafaicon: LayoutItemSeoFafaicon,
    node__layout_item_seo_text_box: LayoutItemSeoTextBox,
    node__layout_service_process_container: LayoutServiceProcessContainer,
    node__layout_full_container: LayoutFullContainer,
    node__layout_slideshow_container: LayoutSlideshowContainer,
    node__layout_slideshow_item: LayoutSlideshowItem,
    node__layout_portfolio_banner: LayoutPortfolioBanner,
    node__layout_item_portfolio_slideshow: LayoutPortfolioSlideshow,
    node__layout_item_homepage_slideshow: LayoutHomepageSlideshow,
    node__layout_item_resources_filter: LayoutResourcesFilter,
    node__layout_item_lets_talk: LayoutLetsTalk,
    node__layout_chat_sidebar: LayoutChatSidebar,
    media__slideshow: DrupalFile,
    media__image: DrupalFile
};

export const getLayoutItem = (node, pageContext, location) => {

    if (components.hasOwnProperty(node.type)) {
        const LayoutComponent = components[node.type];
        return <LayoutComponent key={node.id} node={node} pageContext={pageContext} location={location} />;
    }
    //console.log('Unknown type: ' + node.type);
    return;
};