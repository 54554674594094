import React from 'react';
import HtmlParser from '../helpers/htmlParser';
import { getLayoutItem } from '../helpers/layoutHelper';

export function LayoutItemServiceBannerBox({ node }) {
    const layout_items = Object.values(node.relationships).map(getLayoutItem);
    const { field_service_banner_text, field_service_banner_class, field_active } = node.attributes;
    return (
        <div className={`${field_active} service-banner__container`} id={field_service_banner_class}>
            <div className="service-banner__container--text">
                <HtmlParser html={ field_service_banner_text?.processed }/>
            </div>
            {layout_items}
        </div>
    );
}
