import React from 'react';
import HtmlParser from '../helpers/htmlParser';

function showFreeConsultation() {

    if (document.getElementById) {
        var fcOpen = document.getElementById("consultation__popup--box");
        fcOpen.classList.add("visible");
    }
    return false;

}

export function LayoutConsultation({ node }) {

    const { field_consultation_link, field_consultation_text, title, field_consultation_color } = node.attributes;

    console.log(field_consultation_color);
    return (
        <>
            <section className={`consultation__container ${field_consultation_color}`}>
                <div className="hide-this-element">
                    <h2>{title}</h2>
                </div>
                <div className="consultation__container--box">
                    <div className="consultation__container--box--text">
                        <HtmlParser html={ field_consultation_text }/>
                    </div>
                    <div className="consultation__container--box--btn">
                        <button onClick={() => showFreeConsultation()}>{field_consultation_link?.title}</button>
                    </div>
                </div>
            </section>
        </>
    );
}
