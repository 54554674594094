import React from 'react';
import { getLayoutItem } from '../helpers/layoutHelper';
import HtmlParser from '../helpers/htmlParser';

export function LayoutOurTeamSingle({ node }) {

    const { field_our_team_job_title, field_our_team_name, field_our_team_textbox } = node.attributes;
    const layout_items = Object.values(node.relationships).map(getLayoutItem);
    return (
        <>
            {/* <pre>{JSON.stringify(node, {}, 4)}</pre> */}
            <div className="our-team__single--box">
                <div className="our-team__single--box--title">
                    <h1>{ field_our_team_name }</h1>
                    <h2>{ field_our_team_job_title }</h2>
                </div>
                <div className="our-team__single--box--container">
                    <div className="our-team__single--box--container--image">{ layout_items }</div>
                    <HtmlParser wrapperClass="our-team__single--box--container--textbox" html={ field_our_team_textbox?.value }/>
                </div>
            </div>
        </>
    );
}
