import React, { useLayoutEffect, useState } from 'react';
import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLayoutItem } from '../helpers/layoutHelper';

function hideFilterComponent() {

    if (document.getElementById) {
        var fcClose = document.getElementById("chat-sidebar--boxes");
        fcClose.classList.remove("visible__filter");
        fcClose.classList.add("dismiss");
        var fcIconClose = document.getElementById("chat-sidebar--icon");
        fcIconClose.classList.remove("hide__filter");
        var fcIconDivClose = document.getElementById("chat-sidebar--container");
        fcIconDivClose.classList.remove("hide__filter");
    }
    return false;
  
  }
  
  function showFilterComponent() {
  
    if (document.getElementById) {
      var fcClose = document.getElementById("chat-sidebar--boxes");
      fcClose.classList.add("visible__filter");
      fcClose.classList.remove("dismiss");
      var fcIconClose = document.getElementById("chat-sidebar--icon");
      fcIconClose.classList.add("hide__filter");
      var fcIconDivClose = document.getElementById("chat-sidebar--container");
      fcIconDivClose.classList.add("hide__filter");
    }
    return false;
  
}

export function LayoutChatSidebar({ node }) {

    const layout_items = Object.values(node.relationships).map(getLayoutItem);

    if(typeof window !== 'undefined' && window.document) {
        if (typeof document !== `undefined`) {
            if (document.getElementsByClassName('chat-sidebar')){
                {
                    function getScrollTop() {
                        if (typeof window.pageYOffset !== "undefined") {
                            // Most browsers
                            return window.pageYOffset;
                        }
                    
                        var d = document.documentElement;
                        if (typeof d.clientHeight !== "undefined") {
                            // IE in standards mode
                            return d.scrollTop;
                        }
                    
                        // IE in quirks mode
                        return document.body.scrollTop;
                    }
                    
                    window.onscroll = function () {
                        if (document.getElementById("chat-sidebar--container") !== null) {
                            var box = document.getElementById("chat-sidebar--container");
                            var scroll = getScrollTop();
                            
                            if (scroll <= 800) {
                                box.style.top = "800px";
                            } else {
                                box.style.top = (scroll - 300) + "px";
                            }
                        }  else {
                            return false
                        }
                    };
                };
            }
        }
     }

    const [scrollPosition, setPosition] = useState(0);
    useLayoutEffect(() => {
      function updatePosition() {
        setPosition(window.pageYOffset);
      }
      window.addEventListener('scroll', updatePosition);
      updatePosition();
      return () => window.removeEventListener('scroll', updatePosition);
    }, []);

    if (scrollPosition > 300) {
        return (
            <section id="chat-sidebar--container" className="chat-sidebar">
                <div id="chat-sidebar--icon" className="chat-sidebar--icon" aria-hidden="true" onKeyDown={() => showFilterComponent()} onClick={() => showFilterComponent()}>
                <FontAwesomeIcon icon={['fas', 'comments']} />
                </div>
                <div id="chat-sidebar--boxes" className="chat-sidebar--boxes">
                    <div id="chat-sidebar--close" className="chat-sidebar--close" aria-hidden="true" onKeyDown={() => hideFilterComponent()} onClick={() => hideFilterComponent()}>
                    <FontAwesomeIcon icon={['fas', 'times']} />
                    </div>
                    <div className="chat-sidebar--boxes--box">
                        <div className="chat-sidebar--fafaicon--bg"><FontAwesomeIcon icon={['fas', 'comments']} /></div>
                        <div className="chat-sidebar--categories">
                            {layout_items}
                        </div>
                    </div>
                </div>
            </section>
        );
    } else {
        return (
            <section id="chat-sidebar--container" className="chat-sidebar"></section>
        );
    }
}
