/* eslint-disable */

import { getLayoutItem } from '../helpers/layoutHelper';
import React, { useState } from 'react';


export function LayoutSlideshowContainer({ node }) {

    const [slideIndex, setSlideIndex] = useState(1);

    function plusSlides(n) {
        var add = slideIndex
        showSlides(add += n);
    }
    
    function currentSlide(n) {
        if(n === 0){
            var id = "slide-item-1";
        }
        if(n === 1){
            id = "slide-item-2";
        }
        if(n === 2){
            id = "slide-item-3";
        }
    
        if (document.getElementById) {
            var divid = document.getElementById(id);
            var divs = document.getElementsByClassName("slideshow__container--box");
    
            Array.from(divs).forEach(function (web) {
                if (web.classList.contains("active-slide")) {
                    web.classList.remove("active-slide");
                }
            })
            divid.classList.add("active-slide");
        }
        return false;
        
    }
    
    function showSlides(n) {
        
        if(n === -1)
        {
            n = 2
        }
        if(n === 3)
        {
            n = 0
        }
        setSlideIndex(n)

        if(n === 0){
            var id = "slide-item-1";
        }
        if(n === 1){
            id = "slide-item-2";
        }
        if(n === 2){
            id = "slide-item-3";
        }
    
        if (document.getElementById) {
            var divid = document.getElementById(id);
            var divs = document.getElementsByClassName("slideshow__container--box");
    
            Array.from(divs).forEach(function (web) {
                if (web.classList.contains("active-slide")) {
                    web.classList.remove("active-slide");
                }
            })
            divid.classList.add("active-slide");
        }
        return false;
    
    }


    const layout_items = Object.values(node.relationships).map(getLayoutItem);
    //const { } = node.attributes;
    return (
        <>
            <section className="slideshow__container">
                <div className="hide-this-element">
                    <h2>Testimonials</h2>
                </div>
                {layout_items}
                <div className="slideshow__container--prev" onClick={() => plusSlides(-1)}></div>
                <div className="slideshow__container--next" onClick={() => plusSlides(1)}></div>
                <div className="slideshow__dot">
                    <span className="slideshow__dot--container" onClick={() => currentSlide(0)}></span>
                    <span className="slideshow__dot--container" onClick={() => currentSlide(1)}></span>
                    <span className="slideshow__dot--container" onClick={() => currentSlide(2)}></span>
                </div>
            </section>
        </>
    );
}
