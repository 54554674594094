import React from 'react';
import { Link } from 'gatsby';

import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import HtmlParser from '../helpers/htmlParser';

function showFreeConsultation() {

    if (document.getElementById) {
        var fcOpen = document.getElementById("consultation__popup--box");
        fcOpen.classList.add("visible");
    }
    return false;

}

export function LayoutServices({ node }) {

    const { field_services_consultation, field_services_fafaicon, field_services_learn_more, field_services_text, field_services_title } = node.attributes;
    return (
        <>
        {/* <pre>{JSON.stringify(node, {}, 4)}</pre> */}
            <article className="service__boxes--box service__box--last">
                <div className="service__box--a">
                    <div className="service__boxes--box--img1">
                        <Link to={field_services_learn_more.uri?.slice(9)}><FontAwesomeIcon id="service__svg" icon={field_services_fafaicon?.icon_name} /></Link>
                        <Link to={field_services_learn_more.uri?.slice(9)}><h3>{field_services_title}</h3></Link>
                        <div className="service__boxes--learn-more">
                            <Link to={field_services_learn_more.uri?.slice(9)}><p>{field_services_learn_more?.title}</p></Link>
                        </div>
                    </div>
                </div>
                <div className="service__boxes--box--text index-service__box--text">
                    <p>{field_services_text}</p>
                    <Link to="#services" onClick={() => showFreeConsultation()}><HtmlParser html={ field_services_consultation?.title }/></Link>
                </div>
            </article>
        </>
    );
}
