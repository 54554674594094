import React from 'react';
import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLayoutItem } from '../helpers/layoutHelper';

export function LayoutServiceBanner({ pageContext, node, location }) {

    const { title, field_service_fafaicon, field_service_bg_fafaicon } = node.attributes;
    const layout_items = Object.values(node.relationships).map(getLayoutItem);

    return (
        <>
            <div className="resources__top">
                {/*<pre>{JSON.stringify(node, {}, 4)}</pre>*/}
                <div className="hide-this-element">
                    <h2>{title}</h2>
                </div>
                <div className="resources__top--fafaicon">
                    <div className="resources__top--fafaicon--container">
                        <FontAwesomeIcon icon={field_service_fafaicon?.icon_name} />
                    </div>
                </div>
                <div className="resources__top--bg--fafaicon">
                    <div className="resources__top--bg--fafaicon--container">
                        <FontAwesomeIcon icon={field_service_bg_fafaicon?.icon_name} />
                    </div>
                </div>
                <div>
                    {layout_items}
                </div>
            </div>
        </>
    );
}
