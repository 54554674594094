import React from 'react';

import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function LayoutFafaicon({ node }) {

    const { field_layout_fafaicon, field_simple_fafaicon_container } = node.attributes;

    const layoutClass = field_simple_fafaicon_container;

    if (layoutClass !== null) {
        var containerClass = layoutClass;
    } else {
        containerClass = "";
    }

    function fafaIcon() {
        if (field_layout_fafaicon.icon_name !== "") {
            return <FontAwesomeIcon icon={[field_layout_fafaicon?.style, field_layout_fafaicon?.icon_name]} />
        } else {
            return false;
        }
    }

    return (
        <>
            <div className={`project__top ${containerClass}`}>
                <div className="project__top--fafaicon">
                    <div className="project__top--fafaicon--container">
                        {/*<FontAwesomeIcon icon={[field_layout_fafaicon?.style, field_layout_fafaicon?.icon_name]} />*/}
                        {fafaIcon()}
                    </div>
                </div>
            </div>
        </>
    );
}
