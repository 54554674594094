import React from 'react';
import HtmlParser from '../helpers/htmlParser';
import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function showHideSeo(id, fafaid){

    //if (document.getElementsByClassName('service-process__container--box').length !== 0){

    //    const quotes = document.querySelectorAll(".seo__top");
    //    quotes.forEach(function (_quote) {
    //        _quote.addEventListener("click", function () {
    //            quotes.forEach(function (_active_quote) {
    //                _active_quote.classList.remove("seo--active");
    //                _quote.classList.add("seo--active");
    //            })
    //        })
    //    });
    //}

    if (document.getElementById) {
        var seofafadivid = document.getElementById(fafaid);
        var seofafadivs = document.getElementsByClassName("seo__top");

        Array.from(seofafadivs).forEach(function (seo) {
            if (seo.classList.contains("seo--active")) {
                seo.classList.remove("seo--active");
            }
        })
        seofafadivid.classList.add("seo--active");
    }

    if (document.getElementById) {
        var seodivid = document.getElementById(id);
        var seodivs = document.getElementsByClassName("seo-text-box--container");

        Array.from(seodivs).forEach(function (seo) {
            if (seo.classList.contains("seo-box-active")) {
                seo.classList.remove("seo-box-active");
            }
        })
        seodivid.classList.add("seo-box-active");
    }
    return false;
}

export function LayoutItemSeoFafaicon({ node }) {

    const { title, field_seo_fafaicon, field_seo_fafaicon_title, field_seo_onclick_id, field_seo_fafa_active, field_seo_fafa_id } = node.attributes;

    function fafaIcon() {
        if (field_seo_fafaicon.icon_name !== "") {
            return <FontAwesomeIcon icon={[field_seo_fafaicon?.style, field_seo_fafaicon?.icon_name]} />
        } else {
            return false;
        }
    }
    
    return (
        <>
            <div id={field_seo_fafa_id} aria-hidden="true" onKeyDown={() => showHideSeo(field_seo_onclick_id, field_seo_fafa_id)} className={`${field_seo_fafa_active} seo__top`} onClick={() => showHideSeo(field_seo_onclick_id, field_seo_fafa_id)}>
                <div className="hide-this-element">
                    <h2>{title}</h2>
                </div>
                <div className="seo__top--fafaicon">
                    <div className="seo__top--fafaicon--container">
                        {/*<FontAwesomeIcon icon={[field_seo_fafaicon?.style, field_seo_fafaicon?.icon_name]} />*/}
                        {fafaIcon()}
                    </div>
                </div>
                <p>
                    <HtmlParser html={ field_seo_fafaicon_title }/>
                </p>
            </div>
        </>
    );
}
