import React from 'react';
import HtmlParser from '../helpers/htmlParser';

export function LayoutTextBox({ node }) {

    const { field_layout_text_box, field_textbox_class } = node.attributes;

    return (
        <>
            <div className={`${field_textbox_class} second__article--container--text`}>
                <HtmlParser html={ field_layout_text_box?.processed }/>
            </div>
        </>
    );
}
