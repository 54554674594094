import React from 'react';

function showHideElement(id) {
    
    if (id.includes("service")){
        if (document.getElementById) {
            var servicedivid = document.getElementById(id);
            var servicedivs = document.getElementsByClassName("service-banner__container");
    
            Array.from(servicedivs).forEach(function (service) {
                if (service.classList.contains("service-banner__container-active")) {
                    service.classList.remove("service-banner__container-active");
                }
            })
            servicedivid.classList.add("service-banner__container-active");
        }
        return false;
    } if (id === "getintouch"){
        if (document.getElementById) {
            var fcOpen = document.getElementById("consultation__popup--box");
            fcOpen.classList.add("visible");
        }
        return false;
    }
    else {
        window.scrollBy(0,500);
    }
    
}

export function LayoutItemServiceButton({ node }) {

    const { field_service_btn_onclick, field_service_banner_btn } = node.attributes;
    return (
        <div className="service-banner__container--btn">
            <button onClick={() => showHideElement(field_service_btn_onclick)}>{field_service_banner_btn}</button>
        </div>
    );
}
