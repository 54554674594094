import React from 'react';
import { getLayoutItem } from '../helpers/layoutHelper';

export function LayoutServicesContainer({ node }) {

    const layout_items = Object.values(node.relationships).map(getLayoutItem);
    //const { } = node.attributes;
    return (
        <>
            <section className="service__article">{layout_items}</section>
        </>
    );
}
