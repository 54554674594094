import React from 'react';
import DrupalWebform from '../components/webform.js'

export function LayoutWebform({ node }) {
    //const text = node?.attributes?.field_text || '';
    const webform_id = node?.relationships?.[0]?.attributes.drupal_internal__id || null;
    const thank_you_url = node?.attributes?.field_thank_you_page?.url || 'missing';
    return (
        <>
            {/*kecske: {JSON.stringify(node, {}, 4)} */}
            <DrupalWebform
                wrapperClass='drupal-webform'
                id={webform_id}
                thankYouUrl={thank_you_url}
            />
        </>
    );
}
