import React from 'react';
import { getLayoutItem } from '../helpers/layoutHelper';

export function LayoutContainer({ node }) {

    const { title, field_container_class } = node.attributes;
    const layout_items = Object.values(node.relationships).map(getLayoutItem);
    const layoutClass = field_container_class;

    if (layoutClass !== null) {
        var containerClass = layoutClass;
    } else {
        containerClass = "";
    }
    return (
        <>
            <div className={`layout__container ${containerClass}`} key={title}>{layout_items}</div>
        </>
    );
}
