
import { graphql, useStaticQuery, navigate  } from 'gatsby';
import React from 'react'
import Webform from '../drupal_webform';

const DrupalWebform = ({ id, thankYouUrl }) => {
    let webform;
 
    //return('kecske');
    
    const webforms = useStaticQuery(graphql`
            query WebFormQuery{
                allWebformWebform {
                    nodes {
                        id
                        drupal_internal__id
                        elements {
                            name
                            type
                            attributes {
                                name
                                value
                            }
                            options {
                                #name: label
                                #label
                                value
                            }
                        }
                    }
                }
            }
        `);
    for (const item of webforms.allWebformWebform.nodes) {
        
        if (item) {
            if (item.drupal_internal__id === id) {
                webform = item;
                break;
            }
        }
    }
    if (webform) {
        return <Webform
            id="webform"
            webform={webform}
            endpoint='https://backend.activemedia.com/react_webform_backend/submit'
    
            onSuccess={() => {
                
                navigate(thankYouUrl)
                
            }}
        />
    } else {
        return <>Bad webform id</>;
    }
}

export default DrupalWebform;
