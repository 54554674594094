import React from 'react';
import { getLayoutItem } from '../helpers/layoutHelper';

export function LayoutSingleServiceContainer({ node }) {

    const {field_s_service_container_class} = node.attributes;
    const layout_items = Object.values(node.relationships).map(getLayoutItem);

    return (
        <>
            <section className={`service__table ${field_s_service_container_class}`}>
                <div className="service__table--boxes">{layout_items}</div>
            </section>
        </>
    );
}
