import React from 'react';
import { getLayoutItem } from '../helpers/layoutHelper';

export function LayoutAskForQuoteSidebar({ node }) {

    const layout_items = Object.values(node.relationships).map(getLayoutItem);
    
    const { field_afq_sidebar_class } = node.attributes;

    const askForQuoteDiv = (field_afq_sidebar_class) => {
        if (field_afq_sidebar_class) {
            if (field_afq_sidebar_class.includes("web-design")) {
                return (
                    <div className="ask-for-help__sidebar active-button" id={field_afq_sidebar_class}>{layout_items}</div>
                )
            }
            if (field_afq_sidebar_class.includes("mobile-services")) {
                return (
                    <div className="ask-for-help__sidebar" id={field_afq_sidebar_class}>{layout_items}</div>
                )
            }
        }
    }

    return (
        <>
            {askForQuoteDiv(field_afq_sidebar_class)}
        </>
    );
}
