import React from 'react';
import HtmlParser from '../helpers/htmlParser';
import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function LayoutFafaiconWithText({ node }) {

    const { title, field_fafaicon, field_fafaicon_box_text, field_fafaicon_container } = node.attributes;

    const layoutClass = field_fafaicon_container;

    if (layoutClass !== null) {
        var containerClass = layoutClass;
    } else {
        containerClass = "";
    }

    function fafaIcon() {
        if (field_fafaicon.icon_name !== "") {
            return <FontAwesomeIcon icon={[field_fafaicon?.style, field_fafaicon?.icon_name]} />
        } else {
            return false;
        }
    }
    
    return (
        <>
            <div className={`service__top ${containerClass}`}>
                <div className="hide-this-element">
                    <h2>{title}</h2>
                </div>
                <div className="service__top--fafaicon">
                    <div className="service__top--fafaicon--container">
                        {/*<FontAwesomeIcon icon={[field_fafaicon?.style, field_fafaicon?.icon_name]} />*/}
                        {fafaIcon()}
                    </div>
                </div>
                <div className="fafa-text-container">
                    <div className="service__top--textbox">
                        <HtmlParser html={ field_fafaicon_box_text?.processed }/>
                    </div>
                </div>
            </div>
        </>
    );
}
