import React from 'react';
import HtmlParser from '../helpers/htmlParser';
import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function LayoutSidebarFafaicon({ node }) {

    const { title, field_sidebar_fafaicon, field_sidebar_fafaicon_title } = node.attributes;

    function fafaIcon() {
        if (field_sidebar_fafaicon.icon_name !== "") {
            return <FontAwesomeIcon icon={[field_sidebar_fafaicon?.style, field_sidebar_fafaicon?.icon_name]} />
        } else {
            return false;
        }
    }

    return (
        <>
            <div className="sidebar__top">
                {/*<pre>{JSON.stringify(node, {}, 4)}</pre>*/}
                <div className="hide-this-element">
                    <h2>{title}</h2>
                </div>
                <div className="sidebar__top--fafaicon">
                    <div className="sidebar__top--fafaicon--container">
                        {/*<FontAwesomeIcon icon={[field_sidebar_fafaicon?.style, field_sidebar_fafaicon?.icon_name]} />*/}
                        {fafaIcon()}
                    </div>
                </div>
                <div>
                    <HtmlParser html={ field_sidebar_fafaicon_title?.processed }/>
                </div>
            </div>
        </>
    );
}
