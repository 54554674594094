import React from 'react';
import { getLayoutItem } from '../helpers/layoutHelper';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/navigation"
import "swiper/css/thumbs"

import SwiperCore, {
    Navigation, Pagination
  } from 'swiper';

  SwiperCore.use([Navigation,Pagination]);

export function LayoutHomepageSlideshow({ node }) {
    const layout_items = Object.values(node.relationships).map(getLayoutItem);

    return (
        <div className="homepage-swiper__container">
            <div className="homepage-swiper__container--main">
                <Swiper
                modules={[Navigation,Pagination]}
                spaceBetween={10}
                slidesPerView={1}
                navigation={true}
                loop={true}
                pagination={{clickable: true}}
                >
                    {layout_items.map((desc) => (
                        <SwiperSlide>
                            <div className="slide-text-box">{desc}</div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}
