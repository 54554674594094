import React from 'react';
import { getLayoutItem } from '../helpers/layoutHelper';
import HtmlParser from '../helpers/htmlParser';

export function LayoutProjects({ node }) {

    const { field_project_details, field_project_title, field_project_url, field_project_cat_class } = node.attributes;
    const layout_items = Object.values(node.relationships).map(getLayoutItem);

    return (
        <>
            {/* <pre>{JSON.stringify(node, {}, 4)}</pre> */}
            <article className="project__boxes--box active-portfolio" id={field_project_cat_class}>
                <a href={field_project_url} className="project__box--a">
                    <div className="project__boxes--box--img">
                        {layout_items}
                    </div>
                    <div className="project__boxes--box--title">
                        <div className="project__boxes--box--title--h3">
                            <h3>{field_project_title}</h3>
                        </div>
                        <div className="project__boxes--box--title--p">
                            <p><HtmlParser html={ field_project_details ? field_project_details.substring(0, 43) : "" }/></p>
                        </div>
                    </div>
                </a>
            </article>
        </>
    );
}
