import React from 'react';

function showFreeConsultation() {

    if (document.getElementById) {
        var fcOpen = document.getElementById("consultation__popup--box");
        fcOpen.classList.add("visible");
    }
    return false;

}

export function LayoutLetsTalk({ node }) {

    const { field_lets_talk_btn_title } = node.attributes;
    return (
        <>
           <div className="lets-talk__container--box">
               <div className="lets-talk__container--box--btn">
                   <button onClick={() => showFreeConsultation()}>{field_lets_talk_btn_title}</button>
               </div>
           </div>
        </>
    );
}
