import React from 'react';
import { getLayoutItem } from '../helpers/layoutHelper';

export function LayoutServiceProcessContainer({ node }) {

    const layout_items = Object.values(node.relationships).map(getLayoutItem);
    //const { } = node.attributes;
    return (
        <>
            <section className="service-process__container">
                <div className="service-process__container--box">{layout_items}</div>
            </section>
        </>
    );
}
