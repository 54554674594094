export const mapTextData = {
    sanfrancisco: {
        maptitle: 'San Francisco Bay Area (Silicon Valley)',
        location: 'san-francisco',
        phone: '+1 650-353-2040',
        fafaicon: 'phone-square-alt',
        fafaicontext: 'Toll-Free:',
        fafaiconnumber: '+1 855-997-9195',
        text1: '2995 Woodside Road #620492',
        text2: 'Woodside, CA 94062',
        text3: 'United States',
        link: 'https://www.google.com/maps/dir/Current+Location/+2995%20Woodside%20Road%20#620492+Woodside+94062'
    },
    washington: {
        maptitle: 'Washington DC / Northern Virginia',
        location: 'washington',
        phone: '+1 703-757-9195',
        fafaicon: 'phone-square-alt',
        fafaicontext: 'Toll-Free:',
        fafaiconnumber: '+1 855-997-9195',
        text1: '2 Wolfe Street, Alexandria, VA 22314',
        text2: 'Vienna, VA 22182',
        text3: 'United States',
        link: 'https://www.google.com/maps/place/2+Wolfe+St,+Alexandria,+VA+22314,+USA/@38.8005711,-77.0400975,17z/data=!4m15!1m8!3m7!1s0x89b7b0593a4b1ea1:0xa07702a332a164f6!2s2+Wolfe+St,+Alexandria,+VA+22314,+USA!3b1!8m2!3d38.8005711!4d-77.0400975!16s%2Fg%2F11c26qz_77!3m5!1s0x89b7b0593a4b1ea1:0xa07702a332a164f6!8m2!3d38.8005711!4d-77.0400975!16s%2Fg%2F11c26qz_77?entry=ttu'
    },
    newyork: {
        maptitle: 'New York City',
        location: 'newyork',
        phone: '+1 650-353-2040',
        fafaicon: 'phone-square-alt',
        fafaicontext: 'Toll-Free:',
        fafaiconnumber: '+1 855-997-9195',
        text1: '688 Avenue of the Americas',
        text2: 'New York, NY 10010',
        text3: 'United States',
        link: 'https://www.google.com/maps/dir/Current+Location/+688%20Avenue%20of%20the%20Americas+New%20York+10010'
    },
    london: {
        maptitle: 'London',
        location: 'london',
        phone: '+44 7772 025261',
        fafaicon: 'phone-square-alt',
        fafaicontext: 'Toll-Free:',
        fafaiconnumber: '+44 7772 025261',
        text1: '64 Compton Road',
        text2: 'Croydon CR0 7JA',
        text3: 'United Kingdom',
        link: 'https://www.google.com/maps/dir/Current+Location/+64%20Compton%20Road+Croydon+CR0%207JA'
    },
    budapest: {
        maptitle: 'Budapest (Carnation)',
        location: 'budapest',
        phone: '+36 1 8875353',
        fafaicon: 'fax',
        fafaicontext: 'Fax:',
        fafaiconnumber: '+36 1 887 5350',
        text1: '134-146 Bocskai Str.',
        text2: 'Budapest 1113',
        text3: 'Hungary',
        link: 'https://www.google.com/maps/dir/Current+Location/+134-146%20Bocskai%20Str.+Budapest+1113'
    },
    dubai: {
        maptitle: 'Abu Dhabi / Dubai',
        location: 'dubai',
        phone: '+971 50 7147786',
        fafaicon: 'fax',
        fafaicontext: 'Fax:',
        fafaiconnumber: '+971 2-6222553',
        text1: 'P.O. Box 106464',
        text2: 'AZ',
        text3: 'United Arab Emirates',
        link: 'https://www.google.com/maps/dir/Current+Location/+P.O.%20Box%20106464++'
    }
};
