import React from 'react';
import { getLayoutItem } from '../helpers/layoutHelper';

export function LayoutOurTeamContainer({ node }) {

    const layout_items = Object.values(node.relationships).map(getLayoutItem);
    //const { } = node.attributes;
    return (
        <>
            <div className="our-team__team--boxes">{layout_items}</div>
        </>
    );
}
