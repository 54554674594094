import React from 'react';
import { getLayoutItem } from '../helpers/layoutHelper';

export function LayoutAboutMiniboxContainer({ node }) {

    const layout_items = Object.values(node.relationships).map(getLayoutItem);
    
    return (
        <>
            <div className="about">
                {layout_items}
            </div>
        </>
    );
}
