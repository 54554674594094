import React from 'react';
import HtmlParser from '../helpers/htmlParser';
import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function LayoutResourcesFafaicon({ pageContext, node, location }) {

    const { title, field_resources_fafaicon, field_resources_fafaicon_text, field_resources_bg_fafaicon } = node.attributes;

    function fafaIcon(data) {
        if (field_resources_fafaicon.icon_name !== "" && data === "main") {
            return <FontAwesomeIcon icon={[field_resources_fafaicon?.style, field_resources_fafaicon?.icon_name]} />
        }
        if (field_resources_bg_fafaicon.icon_name !== "" && data === "bg") {
            return <FontAwesomeIcon icon={[field_resources_bg_fafaicon?.style, field_resources_bg_fafaicon?.icon_name]} />
        }
        else {
            return false;
        }
    }

    return (
        <>
            <div className="resources__top">
                {/*<pre>{JSON.stringify(node, {}, 4)}</pre>*/}
                <div className="hide-this-element">
                    <h2>{title}</h2>
                </div>
                <div className="resources__top--fafaicon">
                    <div className="resources__top--fafaicon--container">
                        {/*<FontAwesomeIcon icon={[field_resources_fafaicon?.style, field_resources_fafaicon?.icon_name]} />*/}
                        {fafaIcon("main")}
                    </div>
                </div>
                <div className="resources__top--bg--fafaicon">
                    <div className="resources__top--bg--fafaicon--container">
                        {/*<FontAwesomeIcon icon={[field_resources_bg_fafaicon?.style, field_resources_bg_fafaicon?.icon_name]} />*/}
                        {fafaIcon("bg")}
                    </div>
                </div>
                <div>
                    <HtmlParser html={ field_resources_fafaicon_text?.processed }/>
                </div>
            </div>
        </>
    );
}
